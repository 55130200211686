import { useAuth } from '@hooks/auth'
import { HomePageContext } from './context'
import { ContextEnum } from '@enums/context_enum'
import { useContextSelector } from 'use-context-selector'
import { Card, Container, Typography, Box, CardContent } from '@mui/material'

const style = {
  containter: {
    py: 8,
    display: 'flex',
    gap: 10,
    flexDirection: 'column',
    alignItems: 'center',
  },
  list: {
    gap: 10,
    display: 'flex',
    flexDirection: { md: 'row', xs: 'column' },
  },
  card: {
    pt: 2,
    justifyContent: 'center',
    width: 250,
  },
  content: {
    display: 'flex',
    gap: 2,
    flexDirection: 'column',
  }
}

export const HomePageView = (): JSX.Element => {
  const { user } = useAuth()

  const handleChoseHub = useContextSelector(
    HomePageContext,
    (s) => s.handleChoseHub
  )

  const handleChoseMustang = useContextSelector(
    HomePageContext,
    (s) => s.handleChoseMustang
  )

  // const handleChoseAcademy = useContextSelector(
  //   HomePageContext,
  //   (s) => s.handleChoseAcademy
  // )

  // const handleChoseJobs = useContextSelector(
  //   HomePageContext,
  //   (s) => s.handleChoseJobs
  // )

  const handleChoseGithub = useContextSelector(
    HomePageContext,
    (s) => s.handleChoseGithub
  )

  return (
    <Container sx={style.containter}>
      <Typography variant="h3">O que você deseja acessar hoje?</Typography>

      <Box sx={style.list}>
        <Card onClick={handleChoseHub} sx={style.card}>
          <CardContent sx={style.content} >
            <Typography variant="h4">Think Hub</Typography>
            <Typography variant="body1">{user?.context === ContextEnum.ORGANIZATION ? 'Envie a sua demanda para o nosso marketplace de desenvolvedores e tenha acesso aos melhores profissionais do mercado na evolução do seu produto!' : 'Tenha acesso a tarefas de desenvolvimento de produtos!'}</Typography>
          </CardContent>
        </Card>

        <Card onClick={handleChoseMustang} sx={style.card}>
          <CardContent sx={style.content} >
            <Typography variant="h4">Think Mustang</Typography>
            <Typography variant="body1">Impulsione a eficiência e qualidade do seu time de tecnologia através do monitoramento e gerenciamento de indicadores de desempenho e cronograma de aprendizagem.
            </Typography>
          </CardContent>
        </Card>

        {/* <Card onClick={handleChoseAcademy} sx={style.card}>
          <CardContent sx={style.content} >
            <Typography variant="h4">Think Academy</Typography>
            <Typography variant="body1">
              Conteúdos de nossos Thinkers.
            </Typography>
          </CardContent>
        </Card>

        <Card onClick={handleChoseJobs} sx={style.card}>
          <CardContent sx={style.content} >
            <Typography variant="h4">Think Vagas</Typography>
            <Typography variant="body1">
              Entre para o nosso time!
            </Typography>
          </CardContent>
        </Card> */}

        <Card onClick={handleChoseGithub} sx={style.card}>
          <CardContent sx={style.content} >
            <Typography variant="h4">Think Templates</Typography>
            <Typography variant="body1">
              Tenha acesso a templates de projeto gratuitamente para iniciar a contrução de seu produto.
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Container>
  )
}
