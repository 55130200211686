import { Container, Wrapper } from './styles'
import { useParams } from 'react-router-dom'
import { PanelMenuItem } from './components/item'
import { Divider, Stack, Typography } from '@mui/material'
import { Users } from '@phosphor-icons/react'
import { useAuth } from '@hooks/auth'

export const PanelMenu = (): JSX.Element | null => {
  const auth = useAuth()
  const { id: organizationId } = useParams<{ id: string }>()
  return (
    <Wrapper>
      <Container>
        <Stack py={2} px={1}>
          <Typography variant="body2" color="primary" fontWeight="bold">
            {auth.user?.name || 'Unknown'}
          </Typography>
          <Typography variant="caption" color="secondary">
            {auth.user?.email || 'unknown'}
          </Typography>
        </Stack>
        <Divider textAlign="left" sx={{ my: 1 }}>
          <Typography variant="caption" color="muted.main">
            Recursos
          </Typography>
        </Divider>
        <PanelMenuItem
          icon={Users}
          label="Membros"
          href={`/organizations/${organizationId}`}
        />
      </Container>
    </Wrapper>
  )
}
