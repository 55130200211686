import { HeaderLogo } from './logo'
import { Stack, Box, Grid } from '@mui/material'
import { DisconnectButton } from './disconnect-button'
import { HeaderConnectionStatus } from './connection-status'

export const HeaderMenuAuthenticated = (): JSX.Element => {
  return (
    <Grid container sx={{ px: 3 }} alignItems="center">
      <Grid item xs={12}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          flex={1}
        >
          <Stack direction="row" spacing={3} alignItems="center">
            <HeaderLogo />
          </Stack>
          <Box />
          <Stack direction="row" spacing={2} alignItems="center">
            <HeaderConnectionStatus />
            <DisconnectButton />
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  )
}
