/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth } from '@hooks/auth'
import { useEffect, useMemo } from 'react'
import { ContextPageContext } from './context'
import { ContextPageControllerProps } from './types'

export const ContextPageController = (
  props: ContextPageControllerProps
): JSX.Element => {
  const { user, redirect, handleNavigate } = useAuth()

  const handleChoseContextProfessional = () => {
    handleNavigate('/professional')
  }

  const handleChoseContextOrganization = () => {
    handleNavigate('/setup')
  }

  useEffect(() => {
    if (user?.context) {
      redirect('/')
    }
  }, [user])

  const store = useMemo(
    () => ({
      handleChoseContextProfessional,
      handleChoseContextOrganization,
    }),
    [handleChoseContextProfessional, handleChoseContextOrganization]
  )

  return (
    <ContextPageContext.Provider value={store}>
      {props.children}
    </ContextPageContext.Provider>
  )
}
