/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react'
import { SetupPageContext } from './context'
import { SetupPageControllerProps } from './types'

export const SetupPageController = (
  props: SetupPageControllerProps
): JSX.Element => {
  const state = useMemo(() => ({}), [])
  return (
    <SetupPageContext.Provider value={state}>
      {props.children}
    </SetupPageContext.Provider>
  )
}
