import { useAuth } from '@hooks/auth'
import { SetupPage } from '@pages/setup'
import { Panel } from '@containers/panel'
import { ContextEnum } from '@enums/context_enum'

export const OrganizationPanel = (): JSX.Element => {
  const { user } = useAuth()

  if (user?.context === ContextEnum.ORGANIZATION && !user?.context_id) {
    return <SetupPage />
  }

  return <Panel />
}
