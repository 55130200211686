/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react'
import { HomePageContext } from './context'
import { HomePageControllerProps } from './types'

export const HomePageController = (
  props: HomePageControllerProps
): JSX.Element => {


  const handleChoseHub = () => {
    window.location.href = ['https://hub', ...window.location.host.split('.').slice(1)].join('.')
  }

  const handleChoseMustang = () => {
    window.location.href = ['https://mustang', ...window.location.host.split('.').slice(1)].join('.')
  }

  const handleChoseAcademy = () => {
    window.location.href = 'https://www.thinkforward.com.br/hub/conteudos'
  }

  const handleChoseJobs = () => {
    window.location.href = 'https://www.thinkforward.com.br/entre-para-o-time'
  }

  const handleChoseGithub = () => {
    window.location.href = 'https://github.com/think-fwd'
  }

  const store = useMemo(
    () => ({
      handleChoseHub,
      handleChoseMustang,
      handleChoseGithub,
      handleChoseAcademy,
      handleChoseJobs,
    }),
    [
      handleChoseHub,
      handleChoseMustang,
      handleChoseGithub,
      handleChoseAcademy,
      handleChoseJobs,
    ]
  )

  return (
    <HomePageContext.Provider value={store}>
      {props.children}
    </HomePageContext.Provider>
  )
}
