import { ApiAction } from '../../hooks/api/action'
import { PaginationType } from '@type/pagination_type'
import { OrganizationEntity } from '../../entities/OrganizationEntity'

export class OrganizationApi extends ApiAction {
  async details(): Promise<OrganizationEntity> {
    const response = await this.http.get<OrganizationEntity>(`/organizations`)
    return response.data
  }

  async find(
    page: number,
    limit: number
  ): Promise<PaginationType<OrganizationEntity>> {
    const response = await this.http.get<PaginationType<OrganizationEntity>>(
      `/organizations?page=${page || 1}&limit=${limit || 10}`
    )
    return response.data
  }

  async create(data: Partial<OrganizationEntity>): Promise<OrganizationEntity> {
    const response = await this.http.post<OrganizationEntity>(
      `/organizations`,
      data
    )
    return response.data
  }

  async update(
    organizationId: string,
    data: Partial<OrganizationEntity>
  ): Promise<OrganizationEntity> {
    const response = await this.http.put<OrganizationEntity>(
      `/organizations/${organizationId}`,
      data
    )
    return response.data
  }

  async delete(): Promise<OrganizationEntity> {
    const response = await this.http.delete<OrganizationEntity>(
      `/organizations`
    )
    return response.data
  }
}
