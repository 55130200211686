/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios'
import { useStorage } from '../storage'
import { useSnackbar } from 'notistack'
import { useCookies } from '@hooks/cookies'
import { UserEntity } from '@entities/UserEntity'
import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom'
import React, { useState, useMemo, createContext, useContext } from 'react'

const Context = createContext(
  {} as {
    user: UserEntity | undefined
    disconnecting: boolean
    setUser: React.Dispatch<React.SetStateAction<UserEntity | undefined>>
    disconnect: () => void
    redirect: (paht: string) => void
    authenticate: (user: UserEntity) => void
    handleNavigate: (
      path: string,
      options?: NavigateOptions | undefined,
      queryparams?: string[]
    ) => void
  }
)

const AuthProvider = (props: {
  apiBaseURL?: string
  children: JSX.Element
}) => {
  const cookie = useCookies()
  const storage = useStorage()
  const location = useLocation()
  const navigate = useNavigate()
  const snackbar = useSnackbar()

  const [disconnecting, setDisconnecting] = useState<boolean>(false)
  const [user, setUser] = useState<UserEntity | undefined>(
    JSON.parse(storage.get('user') as unknown as string)
  )

  const handleNavigate = (
    path: string,
    options?: NavigateOptions | undefined,
    queryparams?: string[]
  ) => {
    const params = []
    const qs = new URLSearchParams(location.search)
    if (qs.has('callback')) params.push(`callback=${qs.get('callback')}`)
    for (const queryparam of queryparams || []) {
      params.push(queryparam)
    }

    navigate(`${path}?${params.join('&')}`, options)
  }

  const redirect = React.useCallback((path = '/') => {
    // check if login was required by third-party think app
    const searchParams = new URLSearchParams(location.search)
    if (searchParams.has('callback')) {
      // it true, then redirect to third-party think app
      const auth = cookie.getCookie('Authorization')
      const redirect = [searchParams.get('callback')]
      if (auth) redirect.push(`Authorization=${auth}`)
      window.location.href = redirect.join('?')
    } else {
      // otherelse naviate to home page
      navigate(`${path}?${location.search}`, { replace: true })
    }
  }, [])

  const authenticate = React.useCallback((user: UserEntity) => {
    // update current application user
    if (user) storage.set('user', user)
    setUser(user)
    if (!user.context) {
      handleNavigate('/context')
    } else {
      redirect()
    }
  }, [])

  const disconnect = React.useCallback(() => {
    setDisconnecting(true)
    axios
      .create({ baseURL: props.apiBaseURL, withCredentials: true })
      .post('/logout')
      .then(() => {
        storage.remove('user')
        setUser(undefined)
        navigate('/', { replace: true })
      })
      .then(() => snackbar.enqueueSnackbar('sua conta foi desconectada...'))
      .catch(() =>
        snackbar.enqueueSnackbar('Falha ao desconectar...', {
          variant: 'error',
        })
      )
      .finally(() => setDisconnecting(false))
    // window.location.reload()
  }, [])

  const authProviderValues = useMemo(
    () => ({
      user,
      disconnecting,
      setUser,
      disconnect,
      authenticate,
      redirect,
      setDisconnecting,
      handleNavigate,
    }),
    [
      user,
      disconnecting,
      setUser,
      authenticate,
      redirect,
      disconnect,
      setDisconnecting,
      handleNavigate,
    ]
  )

  return (
    <Context.Provider value={authProviderValues}>
      {props.children}
    </Context.Provider>
  )
}

const useAuth = () => {
  const context = useContext(Context)
  return context
}

export { AuthProvider, useAuth }
