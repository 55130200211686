import { useAuth } from '@hooks/auth'
import { WrapperComponent } from '@containers/wrapper'
import { LoginFormContainer } from './containers/form'
import { WrapperInnerComponent } from '@containers/wrapper-inner'

import {
  Grid,
  Stack,
  Button,
  Container,
  Typography,
  Divider,
  Box,
} from '@mui/material'

export const LoginView = (): JSX.Element => {
  const { handleNavigate } = useAuth()

  return (
    <WrapperComponent>
      <WrapperInnerComponent>
        <Container sx={{ pt: 12, pb: 16 }}>
          <Grid container spacing={2}>
            <Grid
              item
              sm={3}
              sx={{ display: { xs: 'none', sm: 'block' } }}
            ></Grid>
            <Grid item xs={12} sm={6}>
              <Stack direction="column" spacing={4}>
                <Stack direction="column" spacing={1}>
                  <Typography variant="h1" color="secondary.main">
                    Olá, bem vindo
                  </Typography>
                  <Typography variant="subtitle1" color="muted.main">
                    Informe suas credenciais para acessar a plataforma.
                  </Typography>
                </Stack>
                <Stack direction="column" spacing={1} alignItems="start">
                  <Box width="100%">
                    <LoginFormContainer />
                  </Box>
                  <Button
                    size="small"
                    variant="text"
                    onClick={() => handleNavigate('/forgot-password')}
                  >
                    Esqueci minha senha
                  </Button>
                </Stack>
                <Divider textAlign="center">
                  <Typography variant="body2">ou</Typography>
                </Divider>
                <Stack alignItems="center" justifyContent="center">
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => handleNavigate('/signup')}
                    sx={{ px: 2 }}
                  >
                    Criar uma conta grátis
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </WrapperInnerComponent>
    </WrapperComponent>
  )
}
