import Stack from '@mui/material/Stack'
import { useForm } from 'react-hook-form'
import { SetupPageAboutForm } from './types'
import Typography from '@mui/material/Typography'
import LoadingButton from '@mui/lab/LoadingButton'
import { SetupPageAboutContext } from './context'
import { SetupPageAboutAlert } from './containers/alert'
import { useContextSelector } from 'use-context-selector'
import { FormInputText } from '@components/form-input-text'
import { FormInputFormat } from '@components/form-input-format'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ArrowLeft } from '@phosphor-icons/react'

export const SetupPageAboutView = (): JSX.Element | null => {
  const navigate = useNavigate()
  const error = useContextSelector(SetupPageAboutContext, (s) => s.error)
  const loading = useContextSelector(SetupPageAboutContext, (s) => s.loading)

  const handleSubmitForm = useContextSelector(
    SetupPageAboutContext,
    (s) => s.handleSubmit
  )

  const { handleSubmit, control } = useForm<SetupPageAboutForm>({
    defaultValues: {
      name: '',
      document: '',
    },
  })

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <Stack direction="column" spacing={3}>
        <Stack alignItems="flex-start">
          <Button
            variant="text"
            size="large"
            color="secondary"
            onClick={() => navigate('/context')}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <ArrowLeft size={16} />
              <Typography variant="body1">Voltar</Typography>
            </Stack>
          </Button>
        </Stack>
        <Stack direction="column" spacing={1}>
          <Typography variant="h1" color="secondary.main">
            Organização
          </Typography>
          <Typography variant="subtitle1" color="muted.main" lineHeight={1.4}>
            Com uma organização criada, você pode ter acesso aos produtos da
            Think e convidar sua equipe para usufruir dos nossos serviços junto
            com você.
          </Typography>
        </Stack>
        <Stack direction="column" spacing={4}>
          <SetupPageAboutAlert />
          <Stack direction="column" spacing={3}>
            <FormInputText
              name="name"
              size="medium"
              label="Nome da sua organização"
              control={control}
              error={error?.errors?.name}
            />
            <FormInputFormat
              mask
              name="document"
              inputSize="medium"
              format="##.###.###/####-##"
              label="CNPJ"
              control={control}
              error={error?.errors?.document}
            />
          </Stack>
          <LoadingButton
            type="submit"
            size="large"
            variant="contained"
            loading={loading}
          >
            Criar organização
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  )
}
