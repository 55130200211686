import { Button } from '@mui/material'
import Stack from '@mui/material/Stack'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ArrowLeft } from '@phosphor-icons/react'
import Typography from '@mui/material/Typography'
import LoadingButton from '@mui/lab/LoadingButton'
import { ProfessionalPageAboutForm } from './types'
import { ProfessionalPageAboutContext } from './context'
import { useContextSelector } from 'use-context-selector'
import { FormInputText } from '@components/form-input-text'
import { ProfessionalPageAboutAlert } from './containers/alert'
import { FormInputFormat } from '@components/form-input-format'

export const ProfessionalPageAboutView = (): JSX.Element | null => {
  const navigate = useNavigate()
  const error = useContextSelector(ProfessionalPageAboutContext, (s) => s.error)
  const loading = useContextSelector(
    ProfessionalPageAboutContext,
    (s) => s.loading
  )

  const handleSubmitForm = useContextSelector(
    ProfessionalPageAboutContext,
    (s) => s.handleSubmit
  )

  const { handleSubmit, control } = useForm<ProfessionalPageAboutForm>({
    defaultValues: {
      website: '',
      document: '',
    },
  })

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <Stack direction="column" spacing={3}>
        <Stack alignItems="flex-start">
          <Button
            variant="text"
            size="large"
            color="secondary"
            onClick={() => navigate('/context')}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <ArrowLeft size={16} />
              <Typography variant="body1">Voltar</Typography>
            </Stack>
          </Button>
        </Stack>
        <Stack direction="column" spacing={1}>
          <Typography variant="h1" color="secondary.main">
            Profissional
          </Typography>
          <Typography variant="subtitle1" color="muted.main" lineHeight={1.4}>
            Com sua conta profissional criada, você pode ter acesso aos serviços
            da Think.
          </Typography>
        </Stack>
        <Stack direction="column" spacing={4}>
          <ProfessionalPageAboutAlert />
          <Stack direction="column" spacing={3}>
            <FormInputText
              name="website"
              size="medium"
              label="Link portifólio / Linkedin"
              control={control}
              error={error?.errors?.name}
            />
            <FormInputFormat
              mask
              name="document"
              inputSize="medium"
              format="###.###.###-##"
              label="CPF"
              control={control}
              error={error?.errors?.document}
            />
          </Stack>
          <LoadingButton
            type="submit"
            size="large"
            variant="contained"
            loading={loading}
          >
            Criar conta profissional
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  )
}
