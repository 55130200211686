import { ApiAction } from '../../hooks/api/action'
import { ProfessionalEntity } from '../../entities/ProfessionalEntity'

export class ProfessioanlApi extends ApiAction {
  async details(): Promise<ProfessionalEntity> {
    const response = await this.http.get<ProfessionalEntity>(`/professionals`)
    return response.data
  }

  async create(data: Partial<ProfessionalEntity>): Promise<ProfessionalEntity> {
    const response = await this.http.post<ProfessionalEntity>(
      `/professionals`,
      data
    )
    return response.data
  }

  async update(data: Partial<ProfessionalEntity>): Promise<ProfessionalEntity> {
    const response = await this.http.put<ProfessionalEntity>(
      `/professionals`,
      data
    )
    return response.data
  }

  async delete(): Promise<ProfessionalEntity> {
    const response = await this.http.delete<ProfessionalEntity>(`/professionals`)
    return response.data
  }
}
