import { ProfessionalPageAbout } from './types'
import { ProfessionalPageAboutView } from './view'
import { ProfessionalPageAboutController } from './controller'

export const ProfessionalPageAboutForm = (props: ProfessionalPageAbout): JSX.Element => {
  return (
    <ProfessionalPageAboutController {...props}>
      <ProfessionalPageAboutView />
    </ProfessionalPageAboutController>
  )
}
