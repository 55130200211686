/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import { useApi } from '@hooks/api'
import { useAuth } from '@hooks/auth'
import { SetupPageAboutContext } from './context'
import { ContextEnum } from '@enums/context_enum'
import { useCallback, useMemo, useState } from 'react'
import { OrganizationApi } from '@services/api/organization_api'
import { SetupPageAboutForm, SetupPageAboutControllerProps } from './types'

export const SetupPageAboutController = (
  props: SetupPageAboutControllerProps
): JSX.Element => {
  const api = useApi()
  const { user, redirect, setUser } = useAuth()

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<any | undefined>(undefined)

  const handleSubmit = useCallback((form: SetupPageAboutForm) => {
    setLoading(true)
    setError(undefined)
    api
      .instanceOf<OrganizationApi>(OrganizationApi)
      .create(form)
      .then((organization) => {
        if (user) {
          setUser(
            Object.assign(user, {
              context: ContextEnum.ORGANIZATION,
              context_id: organization.id,
            })
          )
        }
        redirect('/')
      })
      .catch((error) => setError(_.get(error, 'response.data')))
      .finally(() => setLoading(false))
  }, [])

  const state = useMemo(
    () => ({ error, loading, handleSubmit }),
    [error, loading, handleSubmit]
  )

  return (
    <SetupPageAboutContext.Provider value={state}>
      {props.children}
    </SetupPageAboutContext.Provider>
  )
}
