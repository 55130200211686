import { useAuth } from '@hooks/auth'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { SignupFormContainer } from './container/form'
import { WrapperComponent } from '@containers/wrapper'
import { WrapperInnerComponent } from '@containers/wrapper-inner'

export const SignupView = (): JSX.Element => {
  const { handleNavigate } = useAuth()

  return (
    <WrapperComponent>
      <WrapperInnerComponent>
        <Container sx={{ pt: 6, pb: 16 }}>
        <Grid container spacing={2}>
            <Grid
              item
              sm={3}
              sx={{ display: { xs: 'none', sm: 'block' } }}
            ></Grid>
            <Grid item xs={12} sm={6}>
              <Stack direction="column" spacing={4}>
                <Stack direction="column" spacing={1}>
                  <Typography variant="h1" color="secondary.main">
                    Crie sua conta.
                  </Typography>
                  <Typography variant="subtitle1" color="muted.main">
                    Não necessita cartão de crédito
                  </Typography>
                </Stack>
                <Stack direction="column" spacing={1} alignItems="start">
                  <Box width="100%">
                    <SignupFormContainer />
                  </Box>
                </Stack>
                <Divider textAlign="center">
                  <Typography variant="body2">ou</Typography>
                </Divider>
                <Stack alignItems="center">
                  <Button
                    variant="text"
                    size="small"
                    sx={{ px: 2 }}
                    onClick={() => handleNavigate('/')}
                  >
                    Retornar para o login
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </WrapperInnerComponent>
    </WrapperComponent>
  )
}
