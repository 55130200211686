/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react'
import { ProfessionalPageContext } from './context'
import { ProfessionalPageControllerProps } from './types'

export const ProfessionalPageController = (
  props: ProfessionalPageControllerProps
): JSX.Element => {
  const state = useMemo(() => ({}), [])
  return (
    <ProfessionalPageContext.Provider value={state}>
      {props.children}
    </ProfessionalPageContext.Provider>
  )
}
