import { ContextPageContext } from './context'
import { useContextSelector } from 'use-context-selector'
import professional from '@assets/images/professional.jpeg'
import organization from '@assets/images/organization.jpeg'

import {
  Card,
  CardActions,
  Container,
  Typography,
  Button,
  CardMedia,
  CardContent,
  Box,
} from '@mui/material'


export const ContextPageView = (): JSX.Element => {
  const handleChoseContextProfessional = useContextSelector(
    ContextPageContext,
    (s) => s.handleChoseContextProfessional
  )

  const handleChoseContextOrganization = useContextSelector(
    ContextPageContext,
    (s) => s.handleChoseContextOrganization
  )

  return (
    <Container
      sx={{
        py: 8,
        display: 'flex',
        gap: 10,
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h3">
        Selecione como você deseja utilizar a plataforma da Think
      </Typography>
      <Box sx={{ display: 'flex', gap: 10 }}>
        <Card sx={{ width: '50%' }}>
          <CardMedia
            sx={{ height: 240 }}
            image={professional}
            title="green iguana"
          />

          <CardContent
           sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
          >
            <Typography variant="h4">Sou profissional</Typography>
            <Typography variant="body1">
              Quero utilizar as soluções da Think como um profissional autonomo
              de desenvolvimento de software
            </Typography>
          </CardContent>

          <CardActions>
            <Button
              variant="contained"
              onClick={handleChoseContextProfessional}
            >
              Selecionar
            </Button>
          </CardActions>
        </Card>

        <Card sx={{ width: '50%' }}>
          <CardMedia
            sx={{ height: 240 }}
            image={organization}
            title="green iguana"
          />

          <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="h4">Sou empresa</Typography>
            <Typography variant="body1">
              Quero utilizar as soluções da Think como uma empresa
            </Typography>
          </CardContent>

          <CardActions>
            <Button
              variant="contained"
              onClick={handleChoseContextOrganization}
            >
              Selecionar
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Container>
  )
}
